import {
  Icon,
  IconProps,
  LabelVariants,
  Badge,
  Label,
  SpacerSizes,
  Responsive,
  createResponsiveConfig,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { FlightTimeline } from './FlightTimeline';
import { MultiStopPopoverContent } from './MultiStopPopoverContent';
import { TimeAndAirport, TimeAndAirportProps } from './TimeAndAirport';
import { OfferFlightsLeg } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { AirlineLogo } from '@Components/AirlineLogo/AirlineLogo';
import { useDate } from '@Dates/useDateHook';
import { Tooltip } from '@UX/Tooltip/Tooltip';

export interface FlightDetailsProps extends ClassNameProps {
  leg: OfferFlightsLeg;
  icon: IconProps['name'];
  isOpenJaw?: boolean;
  direction: 'inbound' | 'outbound';
  supplier?: string;
  variant?: Responsive<FlightDetailsVariants>;
  expandStops?: boolean;
}

type Config = Record<
  FlightDetailsVariants,
  {
    dateVariant: LabelVariants;
    directionVariant: LabelVariants;
    spacingY: keyof SpacerSizes | 0;
    gridTemplateAreas: string;
    gridTemplateColumns: string;
    timeAndAirportVariant: TimeAndAirportProps['variant'];
    displayFlightDuration: string;
    displayFlightDirection: string;
    displayFlightNumber: string;
  }
>;

/* Variants:
   - compact: hdpanda basket
   - small: offer details, alternative flights (mobile)
   - smallMinimal: hdpanda alt flights (no direction or flight number)
   - large: alternative flights (desktop, tablet)
   - largeMinimal: hdpanda alt flights (desktop, tablet) (no direction or flight number) */
export type FlightDetailsVariants = 'compact' | 'small' | 'smallMinimal' | 'large' | 'largeMinimal';

const flightDetailsConfig = createResponsiveConfig<Config>({
  compact: {
    dateVariant: 'medium',
    directionVariant: 'small',
    gridTemplateAreas: `
        "date     airline "
        "timeline timeline"
      `,
    gridTemplateColumns: '1fr auto',
    spacingY: '2xs',
    timeAndAirportVariant: 'compact',
    displayFlightDuration: 'none',
    displayFlightDirection: 'none',
    displayFlightNumber: 'none',
  },

  /*
    ------------------------------
    | date             | airline |
    ------------------------------
    |          timeline          |
    ------------------------------
  */
  small: {
    dateVariant: 'mediumbold',
    directionVariant: 'small',
    gridTemplateAreas: `
        "date     airline "
        "timeline timeline"
      `,
    gridTemplateColumns: '1fr auto',
    spacingY: 's',
    timeAndAirportVariant: 'default',
    displayFlightDuration: 'flex',
    displayFlightDirection: 'flex',
    displayFlightNumber: 'block',
  },
  smallMinimal: {
    dateVariant: 'mediumbold',
    directionVariant: 'small',
    gridTemplateAreas: `
        "date     airline "
        "timeline timeline"
      `,
    gridTemplateColumns: '1fr auto',
    spacingY: 's',
    timeAndAirportVariant: 'default',
    displayFlightDuration: 'flex',
    displayFlightDirection: 'none',
    displayFlightNumber: 'none',
  },

  /*
    ------------------------------
    | date  | timeline  | airline |
    ------------------------------
  */
  large: {
    dateVariant: 'medium',
    directionVariant: 'medium',
    gridTemplateAreas: '"date timeline airline"',
    gridTemplateColumns: '5fr 9fr 3fr',
    spacingY: 'xs',
    timeAndAirportVariant: 'default',
    displayFlightDuration: 'flex',
    displayFlightDirection: 'flex',
    displayFlightNumber: 'block',
  },
  largeMinimal: {
    dateVariant: 'medium',
    directionVariant: 'medium',
    gridTemplateAreas: '"date timeline airline"',
    gridTemplateColumns: '5fr 9fr 3fr',
    spacingY: 'xs',
    timeAndAirportVariant: 'default',
    displayFlightDuration: 'flex',
    displayFlightDirection: 'none',
    displayFlightNumber: 'none',
  },
});

export const FlightDetails: React.FC<FlightDetailsProps> = ({
  leg,
  icon,
  className,
  isOpenJaw,
  direction,
  variant = 'small',
  supplier,
  expandStops = true,
}) => {
  const { t } = useTranslation();
  const { formatCalendarDate, formatDuration } = useDate();

  // the airline/flight number are the ones of the first segment
  const primarySegment = leg.segments.items[0];
  const stops = leg.segments.items.length - 1;
  const {
    dateVariant,
    directionVariant,
    gridTemplateAreas,
    gridTemplateColumns,
    spacingY,
    timeAndAirportVariant,
    displayFlightDuration,
    displayFlightDirection,
    displayFlightNumber,
  } = flightDetailsConfig.get(variant);

  const stopsBadge =
    stops === 0 ? (
      <Badge
        variant="Neutral"
        size="24"
        sx={{
          borderRadius: '4',
        }}
      >
        {t('flightTicket.direct')}
      </Badge>
    ) : (
      <Badge
        variant="Informative"
        size="24"
        iconPosition={expandStops ? 'right' : undefined}
        icon={expandStops ? 'Markers/TooltipFilled' : undefined}
        sx={{
          borderRadius: '4',
        }}
      >
        {t('flightTicket.stops', { count: stops })}
      </Badge>
    );

  return (
    <div className={className}>
      <div
        sx={{
          display: 'grid',
          gridTemplateAreas,
          gridTemplateColumns,
          rowGap: spacingY,
        }}
      >
        <div sx={{ display: 'flex', gridArea: 'date' }}>
          <Icon
            name={icon}
            size="20"
            sx={{
              marginRight: '2xs',
            }}
          />
          <span sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Label
              variant={dateVariant}
              data-id={`flight-date-${direction}`}
              sx={{
                marginBottom: '5xs',
              }}
            >
              {formatCalendarDate(leg.departureDate)}
            </Label>
            {direction && (
              <Label
                variant={directionVariant}
                sx={{
                  display: displayFlightDirection,
                  color: 'textDimmedheavy',
                }}
              >
                {t(direction)}
              </Label>
            )}
          </span>
        </div>

        <div
          sx={{
            display: 'grid',
            gridArea: 'timeline',
            gridTemplateColumns: 'auto 1fr',
          }}
        >
          <FlightTimeline
            sx={{
              gridColumn: 1,
              gridRow: '1 / span 3',
              marginRight: 'xs',
              marginLeft: '3xs',
            }}
          />

          <TimeAndAirport
            variant={timeAndAirportVariant as TimeAndAirportProps['variant']}
            airportName={leg.departureAirport.name}
            time={leg.departureTime}
          />

          <div
            sx={{
              display: displayFlightDuration,
              alignItems: 'center',
              marginY: ['2xs', '3xs'],
            }}
          >
            <Badge
              variant="Neutral"
              size="24"
              icon="Content/InputTime"
              iconPosition="left"
              sx={{
                marginRight: '4xs',
                borderRadius: '4',
              }}
            >
              {formatDuration(leg.duration)}
            </Badge>
            {stops >= 1 && expandStops && (
              <Tooltip
                as="span"
                label={stopsBadge}
              >
                <MultiStopPopoverContent
                  sx={{
                    minWidth: 'sidebarwidth',
                    cursor: 'auto',
                  }}
                  direction={direction}
                  icon={icon}
                  leg={leg}
                />
              </Tooltip>
            )}
            {(stops === 0 || !expandStops) && stopsBadge}
          </div>

          <TimeAndAirport
            variant={timeAndAirportVariant as TimeAndAirportProps['variant']}
            airportName={leg.arrivalAirport.name}
            time={leg.arrivalTime}
            dates={{
              departure: leg.departureDate,
              arrival: leg.arrivalDate,
            }}
            openJaw={isOpenJaw}
          />
        </div>
        <div sx={{ display: 'flex', gridArea: 'airline', justifySelf: 'self-end' }}>
          <span sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {primarySegment?.operatingCarrier && (
              <Fragment>
                <AirlineLogo
                  carrierCode={primarySegment.operatingCarrier}
                  carrierName={primarySegment.operatingCarrierName}
                />
                <Label
                  variant="medium"
                  sx={{
                    display: displayFlightNumber,
                    color: 'textDimmedheavy',
                    marginTop: '4xs',
                  }}
                >
                  {primarySegment.flightNumber}
                </Label>
                {!!supplier && (
                  <Badge
                    variant="PrimaryYellow"
                    size="24"
                    sx={{ marginTop: '4xs' }}
                  >
                    {supplier}
                  </Badge>
                )}
              </Fragment>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
